@import "nullstyle.scss";
@import "variables.scss";
@import "mixins.scss";
@import "contact.scss";
@import "about.scss";
@import "messages.scss";
@import "404.scss";



:root {
    --fs-footer-phone: 18px; 
    --background-size: 24px 24px;
    --fs-h1: 52px;
    --fs-h1-doc: 52px;
    --fs-general-notes: 18px;
    --fs-note: 20px;
    --fs-individual-note: 14px;
    --frame-space: 20px;
    --tb-height: 80px;
}


body {
    color: $font-main;
    font-family: $font-Light;
    font-size: $fs-p;
    font-weight: 200;
    padding-top: var(--tb-height);
    height: calc(100vh - var(--tb-height));
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

p {
    @include element-text(1.5, $font-main, $font-Light);
    font-size: $fs-p;
}

a {
    border-bottom-color: $luxor-gold;
    color: $luxor-gold;
    font-family: $font-Regular;
    font-size: $fs-a;
    text-underline-offset: 0.2em;
}


h1 {
    @include element-text(1.25, $font-main, $font-Kyiv-Regular);
    font-size: var(--fs-h1);
}

h2 {
    @include element-text(1, $font-main, $font-Kyiv-Regular);
    font-size: $h2-font;
    // font-size: $fs-h3-doc;
}
h3 {
    @include element-text(1.5, $font-main, $font-Light);
    font-size: $fs-h3-doc;
}


.flex-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    > * {
        flex: 1;
    }
}

.line {
    border-bottom: 1px solid $black;
    margin: 180px 0 20px 0;
}

//header start
.flex-space {
    flex: 1;
}



.header {
    height: var(--tb-height);
      
    .container {
        height: 100%;
    }

    &.fixed-top {
        position: fixed;
        top:0;
        left: 0;
        right: 0;
        background-color: $beige-color;
        z-index: 10;
    }
}

.header-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .header-logo img{
        height: 40px;
    }
}

.img-cover {
    object-fit: cover;
    display: block; 
    img { 
        object-fit: cover;
        display: block;
        width: 100%;
        height: 100%;
    }
}

.cover-bottom {
    img {
        object-position: bottom;
    }
}

.cover-bottom-85 {
    img {
        object-position: center 85%;
    }
}

.header-menu {
    font-size: $fs-menu;
    // font-weight: 300;
    ul {
        display: flex;
        li {
            margin-left: 61px;
            &:first-child {
                margin-left: 0;
            }      
            a {
                display: inline-block;
                padding-bottom: 0.1em;
                border-bottom: 1px solid transparent;
                text-decoration: none;
                @include element-text(1, $font-main, $font-Light);
                font-size: $fs-menu;
                margin: 0.5em 0;
            }
            &.active {
                a {
                    border-bottom-color: $luxor-gold;
                    color: $luxor-gold;
                    margin: 0.5em 0;
                }
            }
            &:hover {
                a {
                    border-bottom-color: $border;
                    color: $black;
                    margin: 0.5em 0;
                }
            }
        }
    }
}

//header end

//index. section-1 home
.home {
    background-color: $beige-color;
    padding:  90px 0 80px 0;
    .home-block {
        h1 {
            margin-top: -0.3em;
            margin-bottom: 0.6em;
        }
        p {
            max-width: 567px;
        }
        .home-telefon {
            margin-top: auto;
        }
        .content-img {
            margin-left: auto;
        }
    }
    .home-telefon {
        padding: 0 0 3px 18px;
        border-left: 1px solid $black;
        border-bottom: 1px solid $black;
        // border-radius: 5px;
        margin-right: auto;
    }    
}



// .content-img-home {   
//         display: block;
//         height: 524px;
//         width: 621px;

//         img {
//             position: absolute;
//             display: block;
//             height: 503px;
//             width: 600px;
//         }
//         .rect {
//             position: relative;
//             display: block;
//             height: 503px;
//             width: 600px;
//             border: 1px solid #000;
//             top: 20px;
//             left: 20px; 
//         }
//     }





//index.section-1 home end

//index.section-2 notary-services
.notary-services {
    padding: 180px 0;
    .container {
            h2 {
                text-align: center;
                margin-bottom: 80px; 
            }
            h3 {
                @include element-text(1.5, $font-main, $font-Light);
                font-size: $fs-h3-notary-ser;
            }
            .services-list {
                font-size: $container-font;
                text-align: center;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                .services {
                    background: url("../images/rectangle.svg") no-repeat center top;
                    width: 33%;
                    padding-top: 2em;
                    display: inline-block;
                    margin-top: 60px;
                    &:nth-child(-n+3) {
                        margin-top: 0;
                    }
                }
            }
            .services-link {
                text-align: center;
                margin-top: 80px;
                a {
                    font-family: $font-Regular;
                    font-size: $services-link-font;
                }
                a:hover {
                    color: $black;
                }
            }
    }
}

//index.section-2 notary-services end


//index.section-3 job-description
.job-description {
    padding: 80px 0;
    background-color: $beige-color;
    .container {
        font-size: 0;
        h2 {
            text-align: left;
            margin-bottom: 1.5+em;
        }
        .description {
            @include element-text(1.5, $fs-p, $font-Light);
            display: flex;
            justify-content: space-between;
            gap: 39px;
            margin-top: 32px;
            font-size: 20px;
            padding-bottom: 40px;
            padding-right: 40px;
            border-right: 1px solid $black;
            border-bottom: 1px solid $black;
            > * {
                flex: 1;
            }
        }
    }
}


//index.section-3 job-description end

//index.section-4 advantages
.advantages {
    padding: 180px 0 200px 0;
    .container{
        h2 {
            margin-bottom: 86px;
            text-align: center;
        }
        .list-advantages {
            display: flex;
            justify-content: space-between;

            .list-desc {
                padding-left: 40px;
                max-width: 620px;
                li {
                    padding-bottom: 40px;
                    padding-left: 40px;
                    background: url("../images/rectangle.svg") no-repeat 0px 8px;    
                    &:last-child {
                        padding-bottom: 0;
                    } 
                }
                
                hr {
                    clear: both;
                    visibility: hidden;
                }
            }

        }

    }   
}

//index.section-4 advantages end



//index.section-5 pictures
.pictures {    
    .content-img-pictures {
        max-height: 461px;
        max-width: 1280px;
        position: relative;
    }

}


//index.section-5 pictures end



//index.section-6 customer
.customer {
    padding: 200px 0 0 0;
    .container { 
        h2 {
            text-align: left;
            margin-bottom: 80px;
        }  
        .list-customer {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            column-gap: 10px;
            // margin-bottom: 0;
            .list-office {
                li {
                    max-width: 533px;
                    padding-bottom: 40px;
                    padding-left: 2em;
                    background: url("../images/rectangle.svg") no-repeat 0px 5px;
                    &:last-child {
                        padding-bottom: 0;
                    }
                }
                hr {
                    clear: both;
                    visibility: hidden;
                }
            }
        }

    }
        .line {
            border-bottom: 1px solid $black;
            margin: 180px 0 20px 0;
        }
    
    }



//index.section-6 customer end


// Contacts items like phone, email etc


.contacts {
    li {
        margin-bottom: 1em;
    }
    a {
        text-decoration: none;
        @include element-text(1, $font-main, $font-Light);
        background-size: var(--background-size);
        padding-left: 1.75em;
        background-position: 0px 0px;
        background-repeat: no-repeat;
        img {
            text-align: center;  
            width: 20px;
            margin-right: 10px;
        }
    }
    .phone a {
        background-image: url("../images/tel.svg");
    }
    .email a {
        background-image: url("../images/mail.svg") ;
    }
    .map a {
        background-image: url("../images/map.svg");
        background-position-x: 1.5px;
        display: block;
    }
    .time {
        background-image: url("../images/time.svg");
        background-position-y: 0.25em;
        background-repeat: no-repeat;
        padding-left: 1.75em;
    }
}


// Footer
.footer {
    padding: 60px 0;
    background-color: $beige-color;

    // Contacts items like phone, email etc
    .contacts {
        li {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    
    
    .footer_box {
        .contacts a {
            font-size: var(--fs-footer-phone);
            line-height: 1;
        }
        .map a {
            line-height: 1.5;
        }
    }
    
    .fabs { 
        position: fixed;
        #bell {
            margin-bottom: 10px;
            cursor: pointer;
        }
        img {
            height: 60px;
            width: 60px;
            display: block;
            filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
            &:hover {
                filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.4)) brightness(125%);
            }
        }
        
        .jump {
            animation: 4s 2s infinite bounce;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            &:hover {
                animation: none;
                // background-color: #000;
            }
        }
        
        a {
            text-decoration: none;
            
            .icon-viber {
                display: flex;
                align-items: center;

                .button-viber {
                    @include element-text(1, $font-main, $font-Light);
    
                    font-size: $fs-p;
                    padding: 10px 10px;
                    border: 0.25px solid rgba($color: #000000, $alpha: 0.5);
                    border-radius: 10px;
                    background-color: $bg-button;
                    margin-right: 16px; 
                    opacity: 0;
                    transition: opacity 0.2s, transform 0.2s ease; 
                    transform: translateX(100px);
                }
            }
             
            &:hover {
                .button-viber {
            //         align-items: flex-end;
            //         text-decoration: none;
                    opacity: 1;
                    transform: translateX(0px);
                }
            }
        }
    }
}

// Footer end


// .doc-page ul li 

ul {
    width: 100%;
    @include element-text(1.5, $font-main, $font-Light);
    font-size: $fs-ul;
    &.expand {
        display: block;
    } 
}

  
@keyframes dropDown {
    0% {
      display: none;
      opacity: 0.5;
      transform: translateY(-2px);
    }
    1% {
      display: block;
      opacity: 0.5;
      transform: translateY(-2px);
    }
    100% {
      display: block;
      opacity: 1;
      transform: translateY(0px);
    }
}

.expand-ul {
    h3 {
        @include element-text(1.5, $luxor-gold, $font-Regular);
        font-size: $fs-h3-doc;
        background: url("../images/plus.svg") no-repeat 0 0.25em;
        padding-left: 1.5em;  
        margin: 24px 0 16px 0;
        &.expand {
            background-image: url(../images/minus.svg);
        }
        cursor: pointer;
        user-select: none;
    }
    ul {
        display: none;    

        &.expand {
            display: block;
            opacity: 1;
            animation: dropDown 0.25s ease-out;
        }
    }
}

.expand-ul-show {
    ul {
        display: inherit;    
    }
}

.docs-list {
    .individual-note {
        @include element-text(1.5, $font-main, $font-Light);
        font-size: var(--fs-individual-note);
        margin-top: 2.5em;
    }

    h2 {
          @include element-text(1.5, $font-main, $font-Light);
          font-size: $fs-h3-doc;
          margin-bottom: 40px;
          display: block;
          width: 100%;
          max-width: 1015px;
    }

    h3 {
        @include element-text(1.5, $luxor-gold, $font-Regular);
        font-size: $fs-h3-doc;
        margin: 24px 0 16px 0;
        font-weight: 200;
    }



    .note {
        max-width: 950px;
        h2 {
            @include element-text(1, $font-main, $font-Kyiv-Regular);
            font-size: $h2-font;
            margin: 0 0 1.5em 0;
            margin-bottom: 1.5em;
        }
        ul {
            margin: 0 0;
            display: inherit;
            li {
                list-style: none;
                background: url(../images/rectangle.svg) no-repeat 0 center;
                margin-top: 2em;
                padding-left: 2em;
            }
            li:first-child {
                margin-top: 0;
            }
        }
    }   
}


// documents start
.container {
    h1 {
        margin: 1.538em 0 0.615em 0;
    }
}

// Одна секція
.doc-in-list {
    max-width: 1012px;
    margin: 4em 0;
    box-sizing: border-box;
    &:last-child {
        margin-bottom:0;
    }

    .circle-border {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        @include element-text(1, $black, $font-Regular);
        font-size: $fs-number;
        height: 1.4em;
        width: 1.4em;
        min-width: 1.4em;
        border-radius: 50%;
        border: 1px solid $black;
        margin-right: 0.5em;
    }

    h2 {
        line-height: 1;
        display: flex;
        font-weight: 200;
        @include element-text(1, $black, $font-Light);
        font-size: $fs-h2-doc;

    }

    h3 {
        font-size: $container-font;
        padding: 1.8em 0;
        font-weight: 200;
    }

}

.doc-in-list-empty {
    height: 220px;
}

// documents end


// All page for doc  
.doc-page {
    .breadcrumb {
        margin-top: 2.5em;
        font-size: $fs-breadcrumb;
        a {
            color: $black;
            text-decoration: none;
            background: url(../images/back.svg) left center no-repeat;
            padding: 0 0 0 28px;
        }
    }

    h1 { 
        margin: 1em 0 0.6em 0;
        line-height: 1.25;
        max-width: 1100px;
        font-size: var(--fs-h1-doc);

    }

    .notes {
        margin-top: 2em;
    }
    .general-notes {
        font-size: var(--fs-general-notes);
        margin: 1em 0 2em 0;
    }



    h2 {
          @include element-text(1.5, $black, $font-Light);
          font-size: $fs-h2-doc;
          margin-bottom: 40px;
          display: block;
          width: 100%;
          max-width: 1020px;
    }

    h3 {
        @include element-text(1.5, $luxor-gold, $font-Regular);
        font-size: $fs-h3-doc;
        margin: 24px 0 16px 0;
    }
    ul {
        margin-bottom: 2em;
        
        list-style: inherit;
        list-style-position: outside;
        @include element-text(1.5, $font-main, $font-Light);
        font-size: $fs-ul;  
        li {
            padding: 0.2em;
            margin-left: 3.1em;
        }    
    }

    .note {
        li {
            margin-left: 0;
            font-size: var(--fs-note);
            
        }
    }

    .note {
        max-width: 1020px;
        margin-top: 4em;
        h2 {
            @include element-text(1, $font-main, $font-Kyiv-Regular);
            font-size: $h2-font;
            margin: 0 0 1.5em 0;
            margin-bottom: 1.5em;

        }
        ul {
            margin: 0 0;
            display: inherit;
            li {
                list-style: none;
                background: url(../images/rectangle.svg) no-repeat 0 center;
                margin-top: 1.5em;
                padding-left: 2em;
            }
            li:first-child {
                margin-top: 0;
            }
        }
    }

} 

.line-mb {
    margin-bottom: 1em;
}
hr {
    --Line-end-mt-doc: 12em;
    --Line-end-mt: 9em;
    --Line-end-mb: 1em;
    font-size: 20px;
}
.line-end {
    margin: var(--Line-end-mt) 0 var(--Line-end-mb) 0;
}

.line-end-doc {
    margin: var(--Line-end-mt-doc) 0 var(--Line-end-mb) 0;
}


// .block-mt-mb {
//     --block-mt-mb: 4em;
// }

// .block-mt-mb-big {
//     --block-mt: 4em;
// }

:root {
    --block-mt-mb: 4em;
    --block-mt: 4em;
}

.block-mt-mb {
    margin: var(--block-mt-mb) 0;
    max-width: 1020px;
    &:last-child {
        margin-bottom: 0;
    }
    .h2-mb-null {
        margin-bottom: 0;
        }

    .expand-2 {
        li {
            margin-left: 1.2em;
        }
    }
    .no-margin-bottom {
        margin-bottom: 0;   
    }
}

.zapovit {
    margin-bottom: 2em!important;
}

.block-mt-mb-big {
    margin: var(--block-mt) 0 0 0;
}

hr {
    height: 0px;
    background-color: #000;
    border: none;
    position: relative;
    border-top: 1px black solid;
}

.container {
    box-sizing: border-box;
    @include element-text(1.5, $font-main, $font-Light);
}

// ============================================================================
// Images with border frame
// ============================================================================

.aspect-photo-img {
    aspect-ratio: 1.2;
}

.aspect-photo-img-2 {
    aspect-ratio: 1.41;
}

.block-img-rb-border {
    border-right: 1 solid $border;
    border-bottom: 1 solid $border;
    padding-right: var(--frame-space);
    padding-bottom: var(--frame-space);
    picture {
      width: 100%;
      height: auto;
      display: block;
      img {
        display: block;
        width: 100%;
      }
    }
}


.a-rect-top {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    height: calc(100% + var(--frame-space));
    width: 100%;
    border-top: 1px solid $black;
    border-left: 1px solid $black;
    transform:  translateY(calc(-1 * var(--frame-space)));
}

.a-rect-bottom {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    height: calc(100% + var(--frame-space));
    width: 100%;
    border-bottom: 1px solid $black;
    border-right: 1px solid $black;
}

.img {
    height: auto;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.block-img-rb-frame {
    width: 100%;
    position: relative;
    padding-bottom: var(--frame-space);
    .frame {
        border: 1 solid $border;
        position: absolute;
        box-sizing: border-box;
        width: calc(100% - var(--frame-space));
        height: calc(100% - var(--frame-space));
        top: var(--frame-space);
        left: var(--frame-space);
    }

    picture {
        width: calc(100% - var(--frame-space));
        height: auto;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            height: 100%;
        }
    }
}



.block-img-lt-frame {
    width: 100%;
    position: relative;
    .frame {
        border: 1 solid $border;
        position: absolute;
        top: 0px;
        left: 0px;
        box-sizing: border-box;
        width: calc(100% - var(--frame-space));
        height: calc(100% - var(--frame-space));
    }
    picture {
        margin: var(--frame-space) 0 0 var(--frame-space);
        img {
            width: 100%;
        } 
    }
}



.block-img-corners {
    position: relative;
    img, picture { 
        width: 100%;
    }
}


// Media query ================================================================
@import "desktop-up768 and 769-1024.scss";
@import "desktop1025-1150.scss";
@import "table769-1024.scss";
@import "mobile768.scss";