@media screen and (min-width: 1025px) and (max-width: 1151px) {
    .notary-services .container .services-list .services {
        width: 48%;
    }

    .fabs {
        bottom: 60px;
    }      
}

@media screen and (max-width: 1300px) {
    .fabs {
        right: 16px;
    }

}