@media screen and (max-width: 768px) {
    :root {
      --fs-footer-phone: 14px; 
      --background-size: 20px 20px;
      --fs-h1: 36px;
      --fs-h1-doc: 32px;
      --fs-general-notes: 14px;
      --fs-note: 14px;
      --fs-individual-note: 12px;

      --block-mt-mb: 2em;
      --block-mt: 2em;

      --frame-space: 8px;
      --tb-height: 56px;
    }



    .mobOnly {
        display: initial !important;
    }

    .deskOnly {
        display: none !important;
    }

    .flex-box {
        flex-direction: column;
    }

    body {
      font-size: $fs-p-mob;
    }

    // section div {
    //     font-size: $fs-services-p-mob;
    // }
  
    .header {
        
        .container {
            line-height: 1;
            height: 100%;
            .header-main {
                height: 100%;
                padding: 0;
                .header-logo img {
                height: 28px;
                display: block;
                }
            }
        }
    }   
    


    // Burger button
    .burger-hover {
      width: 32px;
      height: 32px;
      right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
      // position: absolute;
  
      .burger-icon {
        height: 12px;
        width: 24px;
  
        .line-1,
        .line-2,
        .line-3 {
          width: 24px;
          border-radius: 1px;
          background-color: $border;
          position: absolute;
          transition: all 0.2s ease;
        }
        .line-1 {
          height: 2px;
          top: calc(50% - 6px);
        }
        .line-2 {
          height: 1px;
          top: 50%;
        }
        .line-3 {
          height: 2px;
          top: calc(50% + 6px);
        }
      }
    }
  
    // Active menu
    .menu-open {
      overflow: hidden;
  
      main {
        pointer-events: none;
      }
      .header-mob-inner {
        visibility: visible;
      }
      .burger-icon {
        .line-1 {
          top: 50%;
          transform: rotate(45deg);
          transform-origin: center;
        }
        .line-2 {
          display: none;
        }
        .line-3 {
          top: 50%;
          transform: rotate(-45deg);
          transform-origin: center;
        }
      }
    }
  
    // Main menu
    .header-mob-inner {
      overflow-y: auto;
      overscroll-behavior: contain;
      inset: 0;
      visibility: hidden;
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      background-color: $beige-color;
      z-index: 1;
      font-size: 32px;
      padding-top: 4em;
      ul {
        flex-direction: column;
        align-items: center;
  
        li {
          margin: 0;
        }
      }
    }
  
    .header-menu ul li a {
      @include element-text(1, $font-main, $font-Kyiv-Regular);
      font-size: $fs-menu-mob;
    }
  
    .container {
      margin: 0;
      padding: 0 16px;
      @include element-text(1.5, $font-main, $font-Light);
  
      h1 {
        margin: 40px 0 24px 0;
      }
  
      h3 {
        font-size: 18px;
      }
  
      p {
        // font-size: $p-fonts-mob-2;
        font-size: $p-fonts-mob;
      }
    }


    // ========================================================================
    // Images with border frame
    // ========================================================================
    .aspect-header-img { aspect-ratio: 1.7; }
    .aspect-folders-img { aspect-ratio: 2.27; }
    .aspect-gavel-img { aspect-ratio: 1.8; }
    .aspect-folders-img { aspect-ratio: 2.35;}

    .block-img-rb-border {
        width: 100%;
    }
    
    .block-img-rb-frame {
        margin: 2.2em 0 0 0;

    }
    //
    //
    //
    .block-img-lt-frame {
      width: 100%;

      img, picture {
        width: calc(100% - var(--frame-space));  
      }
  }

    .block-img-corners {
        margin: var(--frame-space) 0;
    }

    // ========================================================================
    // Document pages
    // ========================================================================

    .block-mt-mb:last-child {
        margin-bottom: 40px;
    }

    .doc-page {
      h2 {
        font-size: $fs-h2-doc-mob;
        margin: 40px 0 16px;
      }
      ul {
        margin-bottom: 2em;
        font-size: $fs-ul-small-mob;
        li {
          margin-left: 2.6em;
          padding: 0.2em;
        }
      }
      .breadcrumb {
        margin-top: 2em;
        font-size: $fs-breadcrumb-mob;
        a {
          font-size: $fs-breadcrumb-mob;
        }
      }
      
      .general-notes {
        margin: 0.7em 0 2em 0;
      }
      .note {
        margin-top: 40px;
        font-size: $fs-ul-small-mob;
        ul li {
          background-size: 20px;
          margin-top: 2em;
        }
        h2 
        {
          font-size: $h2-font-mob;
        }
      }
    }

  
    .footer {
      padding: 43px 0;
    }
  
  
    .contacts {
      font-size: 14px;
    }
  
    .section-block-contacts {
        padding: 60px 0;
        .list-contacts .contacts ul a {
          font-size: $fs-a-contact-mob;
        }
        .list-contacts .contacts ul p {
          font-size: $fs-a-contact-mob;
        }
    }

    .section-block-address {
        h2 {
            margin-top: 80px;
            margin-bottom: 40px;
            text-align: center;
        }
        p {
            margin-bottom: 40px;
        }
    
        .flex-address {
            flex-direction: column;
            display: block;
            .gmap-wrap {
                padding: 0;
                border: 0;
                border: 1px solid silver;
                width: 100%;
                height: 50vh;
                margin: 20px 0 40px 0;
            }
            .qr-wrap {
                display: none;
            }
        }
    }
    
    // End delimiter

    hr {
      --Line-end-mt: 10em;
      --Line-end-mb: 1em;
      font-size: 8px;
    }

    .fabs {
      // right: 16px;
      bottom: 36px;
      
      .jump {
        animation: none;
      }
      .button-viber {
        display: none;
      }
    }
  
    .doc-in-list a {
      font-size: $fs-a-mob;
      line-height: 1;
    }

  
  
    p {
      font-size: $p-fonts-mob-2;
    }

    .expand-p {
      h2 {  
        font-size: $h2-font-small-mob;
        line-height: 1.25;
        background: url(../images/down-arrow.svg) no-repeat 100% 0.45em;
        margin-bottom: 24px;
        padding-right: 1.3em;
        &.expand {
          background-image: url(../images/up-arrow.svg);
        }
        user-select: none;
        cursor: pointer;
      }
      h3 {
        display: none;
        padding-top: 1px;
    
        &.expand {
          font-size: $fs-small-p-mob;
          display: inherit;
          opacity: 1;
          animation: dropDown 0.25s ease-out;
        }
      }
    }
  
    .expand-ul {
      h3 {
        background-position-x: 0;
        background-position-y: center;
        background-size: 18px;
      }
      ul {
        font-size: $fs-ul-small-mob;
      }
    }

    .doc-in-list {
      margin: 2.2em 0;
      h3 {
        padding-right: 1.5em;
      }
    }

    .doc-in-list .circle-border {
      height: 1.5em;
      width: 1.5em;
      min-width: 1.5em;
      font-size: 10px;
      margin-top: 0.2em;
  }
  
    //sec1
    .home {
      padding: 40px 0 60px 0;
      .home-block {
        h1 {
          margin-top: 0;
          margin-bottom: 24px;
        }
        .block-img-rb-frame {
          margin: 2em 0;
      }
        
        .home-telefon {
          width: 275px;
          padding: 0 0 0 10px;
          // a {
          //   font-size: $fs-a-contact-mob;
          // }
          > div {
            margin-bottom: 10px;
            a {
              font-size: $fs-a-contact-mob;
            }
          }
        }
        .contacts li {
          margin-bottom: 0.5em; 
      }
      }
    }
  
    //sec2
  
    h2 {
      font-size: $h2-font-mob;
    }
  
    a {
      font-size: $a-fonts-mob;
    }
  
    .notary-services {
      padding: 80px 0;
      .container {
        h2 {
          text-align: center;
          margin-bottom: 40px;
        }
        h3 {
          font-size: $container-font-mob;
        }
        .services-list {
          font-size: $container-font-mob;
          display: flex;
          flex-direction: column;
          .services {
            background: url("../images/rectangle.svg") no-repeat;
            background-size: 20px 20px;
            background-position: center top;
            width: 100%;
            padding-top: 2em;
            margin-top: 32px;
            &:nth-child(n+2) {
              margin-top: 32px;
            }
          }
        }
        .services-link {
          margin-top: 60px;
          a {
            font-size: $services-link-font-mob;
          }
        }
      }
    }
  
    //sec3
  
    .job-description {
      padding: 60px 0;
      background-color: $beige-color;
      .container {
        font-size: 0;
        h2 {
          text-align: left;
        }
        .description {
          display: block;
          font-size: $fs-ul-mob;
          padding-bottom: 12px;
          padding-right: 12px;
          margin-top: 2.5em;
          .basis {
            padding-bottom: 24px;
            font-size: $fs-ul-mob;
          }
        }
      }
    }
  
    //sec-4
    h3 {
      font-size: $fs-h3-doc-mob;
    }
  
    .advantages {
      padding: 80px 0;
      .container {
        h2 {
          margin-bottom: 40px;
        }
        .list-advantages {

          .list-desc {
            padding-left: 0;
            padding-top: 40px;
            margin-top: 0;
  
            li {
              font-size: $fs-ul-small-mob;
              padding-bottom: 32px;
              padding-left: 1.9em;
              background: url("../images/rectangle.svg") no-repeat;
              background-size: 20px 20px;
              background-position: 0px 4px;
              
            }
          }
        }
      }
    }
  
    //sec-4 end
  
    //sec-5
    // .pictures {
   
    // }
    //sec-5 end
  
    //sec-6
    .customer {
      padding: 80px 0 0 0;
      .container {
        h2 {
          margin-bottom: 40px;
        }
        .list-customer {
          display: block;
          .list-office {
            li {
              font-size: $fs-ul-small-mob;
              padding-bottom: 32px;
              padding-left: 1.9em;
              background: url("../images/rectangle.svg") 0px 5px / 20px 20px
                no-repeat;
              &:last-child {
                padding-bottom: 0;
              }
            }
          }
        }

        .line {
          border-bottom: 1px solid $black;
          margin: 100px 0 8px 0;
        }
      }
    }
  
    //sec-6 end
    
    // ========================================================================
    // About. section photo-block start
    // ========================================================================
    .photo-block {
        .content-img {
            display: flex;
            width: 100%;
            height: auto;
        }
        .block-img-rb-frame {
          margin: 1em 0 0;
      }
    }
  
    // about-mob
    // about. section-olga-biography start
    .section-olga-biography {
        padding: 80px 0;
        .list-biography {
            display: block;
            .text-biography {
                padding-bottom: 40px;
                h3 {
                    margin-bottom: 40px;
                }
                p {
                    font-size: $p-fonts-mob-2;
                    margin-bottom: 1.8em;
                    &:last-child {
                      margin-bottom: 0em;
                    }
                }
            }
            .block-img-rb-frame {
              margin: 0;
          }
          
        }
    }
    //about. section list-customer end
  
    //about. section-documents start
    .section-documents {
        padding: 60px 0;
        background-color: $beige-color;
        
        .container {
            h2 {
                text-align: center;
                margin-bottom: 1.7em;
            }
            .list-documents {
                flex-direction: column;
                gap: 0;
                margin-top: 40px;
                .certificate {
                    background: url("../images/rectangle.svg") no-repeat center top;
                    width: 100%;
                    padding-bottom: 32px;
                    font-size: $fs-small-p-mob;
                    &:last-child {
                      padding-bottom: 40px;
                    }
                    .documents-link {
                      a {
                          text-decoration: none;
                          font-family: $font-Regular;
                          font-size: $fs-small-p-mob;
                      }
                      // a:hover {
                      //     color: $black;
                      // }
                  }
                }
            }
        }
    }
    //about. section-documents end
  
    //about. section-experience start
    .section-experience {
      padding: 80px 0 0 0;
        .container {
            h2 {
                margin-bottom: 40px;
            }
            .text-experience {
                max-width: 1005px;
                li {
                    font-size: $fs-ul-small-mob;
                    background-size: 20px;
                    background-position-y: 0;
                    &:last-child {
                    margin-bottom: 0;
                    }
                }
            }
            .parting-words {
                flex-direction: column;
                align-items: center;
                margin-top: 80px;
                gap: 40px;

                .text-parting-words {
                    text-align: center;
                    p { 
                        font-size: 18px; 
                    }
                }
                .block-img-rb-frame {
                  margin: 0;
              }
            }
        }
    }
    //about. section-experience end

    a.full-button {
        display: block;
        text-decoration: none;
        width: 100%;
        text-align: center;
        padding: 0.75em 0;
        margin: 0;
        background-color: #9B6D2A;
        color: white;
        line-height: 1em;
        & :active {
            background-color: rgba(#9B6D2A, 0.8);
        }
    } 
  }
  