.section-block-contacts {
    background-color: $beige-color;
    padding: 90px 0 80px 0;
    .list-contacts {
        .contacts ul {
            font-size: $fs-p-mob;
            &:last-child {
                margin-bottom: 2em;
            }
            a { 
                font-size: $fs-p; 
            }
        }

        h1 {
            margin-top: -0.3em;
            margin-bottom: 1.2em;
        }
        .home-telefon {   
            div {
                margin-bottom: 20px;
                a {
                    display: flex;
                    align-items: center;
                    @include element-text(1, $font-main, $font-Light);
                    font-size: $fs-p;
                    text-decoration: none;
                    img, img {
                        margin-right: 10px;
                    }
                }
                
            }
            
        }
        .days-of-the-week {
            border-top: 1px solid $black;
            padding-top: 2em;
            flex:1;
            display: inline-block;
            li, p {
                line-height: 2em;
                // font-size: $fs-p-mob;
                font-size: $fs-p;
            }
        }  
        
        .block-img-notarius {
            display: block;
            height: 500px;
            width: 600px;
            padding: 0 20px 20px 0;
            border-bottom: 1px solid $black;
            border-right: 1px solid $black;
            left: 0px;
        }       
    }
}

.section-block-address {

    h2 {
        margin-top: 4.5em;
        margin-bottom: 1.5em;
    }
    p {
        margin-bottom: 1.5em;
    }
    .map a {
        text-decoration: none;
        @include element-text(1.5, $font-main, $font-Light);
        background: url("../images/map.svg") 1.5px center no-repeat;
        padding-left: 1.75em;
        display: block;
        font-size: $fs-a-contact-mob;
    }

    .flex-address {
        align-items: stretch;
        column-gap: 40px;
        .gmap-wrap {
            padding: 0 0 20px 20px;
            border-left: 1px solid $border;
            border-bottom: 1px solid $border;
            width: 100%;
        }
        .qr-wrap {
            border: 1px solid $border;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            height: 553px;
            aspect-ratio: 1;
            img {
                width: 75%;
                margin-bottom: 0.8em;
            }
            div {
                padding: 0 50px;
                text-align: center;
            }

        }
    }
}


.list-contacts.flex-box {
    align-items: flex-start;
}

