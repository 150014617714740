@media screen and (min-width: 769px) and (max-width: 1024px) {
    :root {
        // --fs-footer-phone: 14px; 
        // --background-size: 20px 20px;
        --fs-h1: 52px;
        // --fs-h1-doc: 32px;
        // --fs-general-notes: 14px;
        // --fs-note: 14px;
        // --fs-individual-note: 12px;
  
        // --block-mt-mb: 2em;
        // --block-mt: 2em;
      }

    .notary-services .container {
        h2 {
            margin-bottom: 20px;
        }
        .services-list .services {
        width: 50%;
        &:nth-child(-n+3) {
            margin-top: 60px;
            }
        } 
    }


    .flex-box {
        flex-direction: column;
        align-items: inherit;
    }

    .header-menu ul li {
        margin-left: 40px;
    }
    //sec1
    .home {
        padding: 80px 0;
        .home-block {
          h1 {
            margin-top: 0;
            margin-bottom: 32px;
          }
          p {
            margin-bottom: 60px;
          }
          .home-telefon {
            width: 420px;
            margin-top: 60px;
            padding: 0 0 0 17px;
            .contacts ul {
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 20px;
                    li {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
            }
            > div {
              margin-bottom: 10px;
              a {
                font-size: $fs-a-contact-table;
              }
            }
          }
        }
      }

    // sec 2
      .notary-services {
        padding: 140px 0;
      }

    //sec3
  
    .job-description {
        padding: 80px 0;
        .container {
          .description {
            display: block;
            font-size: $fs-ul-table;
            padding-bottom: 32px;
            padding-right: 20px;
            .basis {
              padding-bottom: 24px;
              font-size: $fs-ul-table;
            }
          }
        }
      }

    //sec-4
     h3 {
        font-size: $fs-h3-doc-table;
      }
    
      .advantages {
        padding: 140px 0 160px 0;
        .container {
          h2 {
            margin-bottom: 60px;
            text-align: start;
          }
          .list-desc {
            padding-left: 0!important;
            padding-top: 60px;
            li {
              font-size: $fs-ul-table-advantages;
              padding-bottom: 40px;
              padding-left: 1.9em;
            background: url("../images/rectangle.svg") no-repeat 0px 4px;
            }
          }
        }
      }

      .block-img-lt-frame {
        width: 75%;

        img {
            width: calc(100% - var(--frame-space));
        }
    }
    
    //sec-4 end
    .customer .container .list-customer {
        width: 75%;
        .list-office li:last-child {
            padding-bottom: 60px;
        }
    }

    hr {
        --Line-end-mt: 10em;
        --Line-end-mb: 1em;
        font-size: 14px;
    }

    .line-end {
        margin: var(--Line-end-mt) 0 var(--Line-end-mb) 0;
    }
    
    //sec-5

    //index.section-6 customer
.customer {
    padding: 160px 0 0 0;
    .container h2 {
        margin-bottom: 60px;
    }
}

// about. section-olga-biography start
.section-olga-biography {
    padding: 140px 0;
    width: 75%;
}

 //about. section-documents start
 .section-documents {
    padding: 80px 0 100px 0;
    background-color: $beige-color;
    
    .container {
        h2 {
            text-align: center;
            margin-bottom: 1em;
        }
        .list-documents {
            flex-direction: column;
            .certificate {
                background: url("../images/rectangle.svg") no-repeat center top;
                width: 70%;
                // padding-bottom: 80px;
                font-size: $fs-p-table;
                margin-left: auto;
                margin-right: auto;
                padding: 40px 0 0 0;
                &:last-child {
                    padding-bottom: 80px;
                }
            }
        }
    }
}
//about. section-documents end

//about. section-experience start
.section-experience {
    padding: 140px 0 0 0;

    .container {
        
        h2 {
            margin-bottom: 1.5em;
        }  
        .text-experience {
            width: 75%;
                li {
                    font-size: $fs-ul;
                    margin-bottom: 2.2em;
                    padding-left: 2.2em;
                    background: url("../images/rectangle.svg")  0px center no-repeat;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
        }
        .parting-words {
            align-items: center;
            margin-top: 140px;
            gap: 60px;
            .text-parting-words {
                // vertical-align: middle;
                text-align: center;
                p {
                    font-size: 24px;
                }
            }
            .block-img-rb-frame {
                width: 75%;
            }
        }
    }
}
//about. section-experience end

//contact start

.section-block-contacts {
    padding: 80px 0;
    .list-contacts {
        width: 75%;
        .contacts ul {
            font-size:  18px;
            display: flex;
            flex-wrap: wrap;
            column-gap: 20px;

            &:last-child {
                margin-bottom: 1em;
            }
            a { 
                font-size: $fs-a-contact-table; 
            }
        }

        h1 {
            margin-top: -0.3em;
            margin-bottom: 1.2em;
        }
        .home-telefon {   
            div {
                margin-bottom: 20px;
                a {
                    display: flex;
                    align-items: center;
                    @include element-text(1, $font-main, $font-Light);
                    font-size: $fs-p;
                    text-decoration: none;
                    img {
                        margin-right: 10px;
                    }
                }
                
            }
            
        }
        .days-of-the-week {
            border-top: 1px solid $black;
            padding-top: 2em;
            flex:1;
            display: inline-block;
            li, p {
                line-height: 2em;
                // font-size: $fs-p-mob;
                font-size: $fs-p;
            }
        }  
        
        .block-img-notarius {
            display: block;
            height: 500px;
            width: 600px;
            padding: 0 20px 20px 0;
            border-bottom: 1px solid $black;
            border-right: 1px solid $black;
            left: 0px;
        }       
    }
}

.section-block-address {

    h2 {
        margin-top: 3.5em;
        margin-bottom: 1.5em;
    }
    p {
        margin-bottom: 2em;
    }
    .map a {
        // text-decoration: none;
        // @include element-text(1.5, $font-main, $font-Light);
        // background: url("../images/map.svg") 1.5px center no-repeat;
        // padding-left: 1.75em;
        // display: block;
        font-size: $fs-a-contact-table;
    }

    .flex-address {
        flex-direction: row;
        .gmap-wrap {
            padding: 0 0 20px 20px;
            border-left: 1px solid $border;
            border-bottom: 1px solid $border;
            width: 50%;
        }
        .qr-wrap {
            // border: 1px solid $border;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // flex-direction: column;
            // // width: 349px;
            height: auto;
            aspect-ratio: 1;
            img {
                width: 53%;
                margin-bottom: 0.8em;
            }
            div {
                font-size: $fs-a-qr-table;
            }
        }
    }
}


.list-contacts.flex-box {
    align-items: flex-start;
}


}

