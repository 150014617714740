#message-layout {
    overflow-y: auto;
    overscroll-behavior: contain;
    inset: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba($color: #000000, $alpha: 0.55);
    z-index: 10;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    .card {
        padding: 1.75em;
        margin: 1em;
        background-color: #F8F7F3;
        border-radius: 4px;
        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
        max-width: 640px;

        display: flex;
        flex-direction: column;
        position: relative;
    
       .title { 
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 32px;
            width: 100%;
            font-size: 24px;
            span {
                padding: 2px 0 2px 1.5em;
                background: url(../images/info.svg) 0 center no-repeat;
                display: inline-block;
            }
        }

        #close-button {
            background: url(../images/close.svg) no-repeat center center;
            width: 24px;
            height: 24px;
            cursor: pointer;
            border-radius: 2px;
            user-select: none;
            &:hover {
                background-color: rgba(#000, 0.1);
            }
        }

        .message {
            padding-right: 70px;
            font-size: 18px;
            position: relative;
            margin-bottom: 2em;
            p {
                margin: 1em 0 0.5em 0;
                &:first-child {
                    margin-top: 0;
                }
            }   
            .contacts {
                margin: 1.5em 0;
                ul {
                    font-size: 18px;
                }
            }            
        }
    }
}
.custom-border {
    width: 50%;
    height: 30%;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    position: absolute;
    right: 2em;
    bottom: 2em;
}



@media screen and (max-width: 768px) { 
    #message-layout { 
        .card {   
            padding: 2em 1em;
            .title {
                font-size: 18px;
            }
            .message {

                font-size: 14px;
                margin-bottom: 0;
                ul {
                    li {
                        line-height: 1.25em;
                    }
                    a {
                        font-size: 14px;
                        background-size: 20px 20px;

                    }

                }
            }       
        }
    }
    .custom-border {
        display: none;
    }
}

// Active menu
.message-open {
    overflow: hidden;

    main {
        pointer-events: none;
    }
    #message-layout {
        visibility: visible;
    }

    .jump {
        animation: none !important;
    }
}