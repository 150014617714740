@font-face {
    font-family: 'e-Ukraine-light';
    src: url('../fonts/e-ukraine-ultralight-200.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'e-Ukraine-light';
    src: url('../fonts/e-ukraine-light-300.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'e-Ukraine-regular';
    src: url('../fonts/e-ukraine-regular-400.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'kyivTypeSans-regular';
    src: url('../fonts/KyivTypeSans-Regular.ttf');
    font-weight: 350;
    font-style: normal;
}



// colors 
$beige-color:#F8F7F3;
$font-main: #000000;
$luxor-gold: #9B6D2A;
$black: black;
$border: black;
$bg-button: #FFFFFF;
$link-color: #0000FF;

// Fonts
$font-Light:'e-Ukraine-light';
$font-Regular: 'e-Ukraine-regular';
$font-Kyiv-Regular: 'kyivTypeSans-regular';

// font size
$container-font: 18px;




$fs-ul: 18px;
$fs-ul-mob: 16px;
$fs-ul-small-mob: 14px;
$fs-ul-table: 20px;
$fs-ul-table-advantages: 18px;

$p-fonts-mob-2: 14px;
$services-link-font: 24px;

$h2-font: 40px;
$title-font: 52px;

$fs-h1-doc: 52px;
$fs-h1-doc-mob: 32px;

$fs-h2-doc: 24px;
$fs-h2-doc-mob: 18px;

$fs-breadcrumb: 16px;
$fs-breadcrumb-mob: 14px;

$fs-header-table: 16px;

$fs-menu: 16px;
$fs-menu-mob: 32px;

$fs-a: 20px; 
$fs-a-mob: 14px;
$fs-a-contact-mob: 18px;
$fs-a-contact-table: 20px;
$fs-a-qr-table: 16px;

$fs-p: 20px;
$fs-p-mob: 18px;
$fs-p-table: 20px;
$fs-small-p: 18px;
$fs-small-p-mob: 14px;

$fs-services-p-mob: 14px;

$fs-number: 16px;
$fs-number-mob: 10px;
// $fs-footer-number-mob: 14px;


$fs-h3-notary-ser: 18px;
$fs-h3-doc: 24px;
$fs-h3-doc-table: 24px;
$fs-h3-doc-mob: 18px;

// $fs-individual-note: 14px;


//
$p-fonts-mob: 18px;
$h2-font-mob: 24px;
$h2-font-small-mob: 18px;
$container-font-mob: 14px;
$a-fonts-mob: 16px;
$services-link-font-mob: 16px;

