html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: e-Ukraine-light;
  src: url("../fonts/e-ukraine-ultralight-200.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: e-Ukraine-light;
  src: url("../fonts/e-ukraine-light-300.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: e-Ukraine-regular;
  src: url("../fonts/e-ukraine-regular-400.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: kyivTypeSans-regular;
  src: url("../fonts/KyivTypeSans-Regular.ttf");
  font-weight: 350;
  font-style: normal;
}

.section-block-contacts {
  background-color: #f8f7f3;
  padding: 90px 0 80px;
}

.section-block-contacts .list-contacts .contacts ul {
  font-size: 18px;
}

.section-block-contacts .list-contacts .contacts ul:last-child {
  margin-bottom: 2em;
}

.section-block-contacts .list-contacts .contacts ul a {
  font-size: 20px;
}

.section-block-contacts .list-contacts h1 {
  margin-top: -.3em;
  margin-bottom: 1.2em;
}

.section-block-contacts .list-contacts .home-telefon div {
  margin-bottom: 20px;
}

.section-block-contacts .list-contacts .home-telefon div a {
  color: #000;
  align-items: center;
  font-family: e-Ukraine-light;
  font-size: 20px;
  line-height: 1;
  text-decoration: none;
  display: flex;
}

.section-block-contacts .list-contacts .home-telefon div a img, .section-block-contacts .list-contacts .home-telefon div a img {
  margin-right: 10px;
}

.section-block-contacts .list-contacts .days-of-the-week {
  border-top: 1px solid #000;
  flex: 1;
  padding-top: 2em;
  display: inline-block;
}

.section-block-contacts .list-contacts .days-of-the-week li, .section-block-contacts .list-contacts .days-of-the-week p {
  font-size: 20px;
  line-height: 2em;
}

.section-block-contacts .list-contacts .block-img-notarius {
  height: 500px;
  width: 600px;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  padding: 0 20px 20px 0;
  display: block;
  left: 0;
}

.section-block-address h2 {
  margin-top: 4.5em;
  margin-bottom: 1.5em;
}

.section-block-address p {
  margin-bottom: 1.5em;
}

.section-block-address .map a {
  color: #000;
  background: url("../images/map.svg") 1.5px no-repeat;
  padding-left: 1.75em;
  font-family: e-Ukraine-light;
  font-size: 18px;
  line-height: 1.5;
  text-decoration: none;
  display: block;
}

.section-block-address .flex-address {
  align-items: stretch;
  column-gap: 40px;
}

.section-block-address .flex-address .gmap-wrap {
  width: 100%;
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  padding: 0 0 20px 20px;
}

.section-block-address .flex-address .qr-wrap {
  text-align: center;
  height: 553px;
  aspect-ratio: 1;
  border: 1px solid #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.section-block-address .flex-address .qr-wrap img {
  width: 75%;
  margin-bottom: .8em;
}

.section-block-address .flex-address .qr-wrap div {
  text-align: center;
  padding: 0 50px;
}

.list-contacts.flex-box {
  align-items: flex-start;
}

.section-olga-biography {
  padding: 180px 0;
}

.section-olga-biography .list-biography {
  align-items: start;
  column-gap: 40px;
}

.section-olga-biography .list-biography .text-biography h3 {
  margin-bottom: 40px;
}

.section-olga-biography .list-biography .text-biography p {
  color: #000;
  margin-bottom: 24px;
  font-family: e-Ukraine-light;
  font-size: 18px;
  line-height: 1.5;
}

.section-documents {
  background-color: #f8f7f3;
  padding: 80px 0 100px;
}

.section-documents .container h2 {
  text-align: center;
}

.section-documents .container .list-documents {
  text-align: center;
  gap: 59px;
  margin-top: 60px;
  font-size: 18px;
  display: flex;
}

.section-documents .container .list-documents .certificate {
  color: #000;
  width: 33.3%;
  background: url("../images/rectangle.svg") top no-repeat;
  justify-content: space-between;
  margin-top: 0;
  padding: 40px 0 80px;
  font-family: e-Ukraine-light;
  line-height: 1.5;
  display: inline-block;
}

.section-documents .container .list-documents .certificate .documents-link a {
  font-family: e-Ukraine-regular;
  font-size: 18px;
  text-decoration: none;
}

.section-documents .container .list-documents .certificate .documents-link a:hover {
  color: #000;
}

.section-experience {
  padding: 180px 0 0;
}

.section-experience .container h2 {
  margin-bottom: 1.5em;
}

.section-experience .container .text-experience {
  max-width: 1005px;
}

.section-experience .container .text-experience li {
  background: url("../images/rectangle.svg") 0 no-repeat;
  margin-bottom: 2.2em;
  padding-left: 2.2em;
  font-size: 18px;
}

.section-experience .container .text-experience li:last-child {
  margin-bottom: 0;
}

.section-experience .container .parting-words {
  gap: 40px;
  margin-top: 180px;
}

.section-experience .container .parting-words .text-parting-words {
  text-align: center;
}

.section-experience .container .parting-words .text-parting-words p {
  font-size: 24px;
}

#message-layout {
  overscroll-behavior: contain;
  visibility: hidden;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  background-color: #0000008c;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  display: flex;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

#message-layout .card {
  max-width: 640px;
  background-color: #f8f7f3;
  border-radius: 4px;
  flex-direction: column;
  margin: 1em;
  padding: 1.75em;
  display: flex;
  position: relative;
  box-shadow: 0 8px 8px #00000040;
}

#message-layout .card .title {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  font-size: 24px;
  display: flex;
}

#message-layout .card .title span {
  background: url("../images/info.svg") 0 no-repeat;
  padding: 2px 0 2px 1.5em;
  display: inline-block;
}

#message-layout .card #close-button {
  width: 24px;
  height: 24px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: url("../images/close.svg") center no-repeat;
  border-radius: 2px;
}

#message-layout .card #close-button:hover {
  background-color: #0000001a;
}

#message-layout .card .message {
  margin-bottom: 2em;
  padding-right: 70px;
  font-size: 18px;
  position: relative;
}

#message-layout .card .message p {
  margin: 1em 0 .5em;
}

#message-layout .card .message p:first-child {
  margin-top: 0;
}

#message-layout .card .message .contacts {
  margin: 1.5em 0;
}

#message-layout .card .message .contacts ul {
  font-size: 18px;
}

.custom-border {
  width: 50%;
  height: 30%;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  position: absolute;
  bottom: 2em;
  right: 2em;
}

@media screen and (width <= 768px) {
  #message-layout .card {
    padding: 2em 1em;
  }

  #message-layout .card .title {
    font-size: 18px;
  }

  #message-layout .card .message {
    margin-bottom: 0;
    font-size: 14px;
  }

  #message-layout .card .message ul li {
    line-height: 1.25em;
  }

  #message-layout .card .message ul a {
    background-size: 20px 20px;
    font-size: 14px;
  }

  .custom-border {
    display: none;
  }
}

.message-open {
  overflow: hidden;
}

.message-open main {
  pointer-events: none;
}

.message-open #message-layout {
  visibility: visible;
}

.message-open .jump {
  animation: none !important;
}

:root {
  --fs-message: 18px;
  --fs-a: 16px;
}

.body-error {
  background-color: #f8f7f3;
  padding-top: 0;
  display: block;
}

.sec-error {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.img-error {
  text-align: center;
  max-width: 794px;
  margin: 0 18%;
}

.img-error img {
  width: 100%;
}

.mes {
  color: #000;
  font-family: e-Ukraine-light;
  line-height: 1.5;
  font-size: var(--fs-message);
  text-align: center;
  white-space: nowrap;
  margin-top: 3em;
}

.mes br {
  display: none;
}

.button {
  background-color: #9b6d2a;
  margin-top: 1.4em;
  padding: 12px 93px;
}

.button a {
  color: #fff;
  font-family: e-Ukraine-light;
  line-height: 1.5;
  font-size: var(--fs-a);
  text-decoration: none;
}

@media screen and (width <= 768px) {
  :root {
    --fs-message: 14px;
    --fs-a: 16px;
  }

  .img-error {
    max-width: 288px;
    margin: 0 5%;
  }

  .message br {
    display: inline;
  }
}

:root {
  --fs-footer-phone: 18px;
  --background-size: 24px 24px;
  --fs-h1: 52px;
  --fs-h1-doc: 52px;
  --fs-general-notes: 18px;
  --fs-note: 20px;
  --fs-individual-note: 14px;
  --frame-space: 20px;
  --tb-height: 80px;
}

body {
  color: #000;
  padding-top: var(--tb-height);
  height: calc(100vh - var(--tb-height));
  flex-direction: column;
  justify-content: space-between;
  font-family: e-Ukraine-light;
  font-size: 20px;
  font-weight: 200;
  display: flex;
}

p {
  color: #000;
  font-family: e-Ukraine-light;
  font-size: 20px;
  line-height: 1.5;
}

a {
  color: #9b6d2a;
  text-underline-offset: .2em;
  border-bottom-color: #9b6d2a;
  font-family: e-Ukraine-regular;
  font-size: 20px;
}

h1 {
  color: #000;
  font-family: kyivTypeSans-regular;
  line-height: 1.25;
  font-size: var(--fs-h1);
}

h2 {
  color: #000;
  font-family: kyivTypeSans-regular;
  font-size: 40px;
  line-height: 1;
}

h3 {
  color: #000;
  font-family: e-Ukraine-light;
  font-size: 24px;
  line-height: 1.5;
}

.flex-box {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.flex-box > * {
  flex: 1;
}

.line {
  border-bottom: 1px solid #000;
  margin: 180px 0 20px;
}

.flex-space {
  flex: 1;
}

.header {
  height: var(--tb-height);
}

.header .container {
  height: 100%;
}

.header.fixed-top {
  z-index: 10;
  background-color: #f8f7f3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header-main {
  height: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header-main .header-logo img {
  height: 40px;
}

.img-cover {
  object-fit: cover;
  display: block;
}

.img-cover img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.cover-bottom img {
  object-position: bottom;
}

.cover-bottom-85 img {
  object-position: center 85%;
}

.header-menu {
  font-size: 16px;
}

.header-menu ul {
  display: flex;
}

.header-menu ul li {
  margin-left: 61px;
}

.header-menu ul li:first-child {
  margin-left: 0;
}

.header-menu ul li a {
  color: #000;
  border-bottom: 1px solid #0000;
  margin: .5em 0;
  padding-bottom: .1em;
  font-family: e-Ukraine-light;
  font-size: 16px;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

.header-menu ul li.active a {
  color: #9b6d2a;
  border-bottom-color: #9b6d2a;
  margin: .5em 0;
}

.header-menu ul li:hover a {
  color: #000;
  border-bottom-color: #000;
  margin: .5em 0;
}

.home {
  background-color: #f8f7f3;
  padding: 90px 0 80px;
}

.home .home-block h1 {
  margin-top: -.3em;
  margin-bottom: .6em;
}

.home .home-block p {
  max-width: 567px;
}

.home .home-block .home-telefon {
  margin-top: auto;
}

.home .home-block .content-img {
  margin-left: auto;
}

.home .home-telefon {
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  margin-right: auto;
  padding: 0 0 3px 18px;
}

.notary-services {
  padding: 180px 0;
}

.notary-services .container h2 {
  text-align: center;
  margin-bottom: 80px;
}

.notary-services .container h3 {
  color: #000;
  font-family: e-Ukraine-light;
  font-size: 18px;
  line-height: 1.5;
}

.notary-services .container .services-list {
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 18px;
  display: flex;
}

.notary-services .container .services-list .services {
  width: 33%;
  background: url("../images/rectangle.svg") top no-repeat;
  margin-top: 60px;
  padding-top: 2em;
  display: inline-block;
}

.notary-services .container .services-list .services:nth-child(-n+3) {
  margin-top: 0;
}

.notary-services .container .services-link {
  text-align: center;
  margin-top: 80px;
}

.notary-services .container .services-link a {
  font-family: e-Ukraine-regular;
  font-size: 24px;
}

.notary-services .container .services-link a:hover {
  color: #000;
}

.job-description {
  background-color: #f8f7f3;
  padding: 80px 0;
}

.job-description .container {
  font-size: 0;
}

.job-description .container h2 {
  text-align: left;
  margin-bottom: 1.5em;
}

.job-description .container .description {
  color: 20px;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  justify-content: space-between;
  gap: 39px;
  margin-top: 32px;
  padding-bottom: 40px;
  padding-right: 40px;
  font-family: e-Ukraine-light;
  font-size: 20px;
  line-height: 1.5;
  display: flex;
}

.job-description .container .description > * {
  flex: 1;
}

.advantages {
  padding: 180px 0 200px;
}

.advantages .container h2 {
  text-align: center;
  margin-bottom: 86px;
}

.advantages .container .list-advantages {
  justify-content: space-between;
  display: flex;
}

.advantages .container .list-advantages .list-desc {
  max-width: 620px;
  padding-left: 40px;
}

.advantages .container .list-advantages .list-desc li {
  background: url("../images/rectangle.svg") 0 8px no-repeat;
  padding-bottom: 40px;
  padding-left: 40px;
}

.advantages .container .list-advantages .list-desc li:last-child {
  padding-bottom: 0;
}

.advantages .container .list-advantages .list-desc hr {
  clear: both;
  visibility: hidden;
}

.pictures .content-img-pictures {
  max-height: 461px;
  max-width: 1280px;
  position: relative;
}

.customer {
  padding: 200px 0 0;
}

.customer .container h2 {
  text-align: left;
  margin-bottom: 80px;
}

.customer .container .list-customer {
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 10px;
  display: flex;
}

.customer .container .list-customer .list-office li {
  max-width: 533px;
  background: url("../images/rectangle.svg") 0 5px no-repeat;
  padding-bottom: 40px;
  padding-left: 2em;
}

.customer .container .list-customer .list-office li:last-child {
  padding-bottom: 0;
}

.customer .container .list-customer .list-office hr {
  clear: both;
  visibility: hidden;
}

.customer .line {
  border-bottom: 1px solid #000;
  margin: 180px 0 20px;
}

.contacts li {
  margin-bottom: 1em;
}

.contacts a {
  color: #000;
  background-size: var(--background-size);
  background-position: 0 0;
  background-repeat: no-repeat;
  padding-left: 1.75em;
  font-family: e-Ukraine-light;
  line-height: 1;
  text-decoration: none;
}

.contacts a img {
  text-align: center;
  width: 20px;
  margin-right: 10px;
}

.contacts .phone a {
  background-image: url("../images/tel.svg");
}

.contacts .email a {
  background-image: url("../images/mail.svg");
}

.contacts .map a {
  background-image: url("../images/map.svg");
  background-position-x: 1.5px;
  display: block;
}

.contacts .time {
  background-image: url("../images/time.svg");
  background-position-y: .25em;
  background-repeat: no-repeat;
  padding-left: 1.75em;
}

.footer {
  background-color: #f8f7f3;
  padding: 60px 0;
}

.footer .contacts li:last-child {
  margin-bottom: 0;
}

.footer .footer_box .contacts a {
  font-size: var(--fs-footer-phone);
  line-height: 1;
}

.footer .footer_box .map a {
  line-height: 1.5;
}

.footer .fabs {
  position: fixed;
}

.footer .fabs #bell {
  cursor: pointer;
  margin-bottom: 10px;
}

.footer .fabs img {
  height: 60px;
  width: 60px;
  filter: drop-shadow(0 2px 4px #0003);
  display: block;
}

.footer .fabs img:hover {
  filter: drop-shadow(0 2px 4px #0006) brightness(125%);
}

.footer .fabs .jump {
  flex-direction: column;
  align-items: flex-end;
  animation: 4s 2s infinite bounce;
  display: flex;
}

.footer .fabs .jump:hover {
  animation: none;
}

.footer .fabs a {
  text-decoration: none;
}

.footer .fabs a .icon-viber {
  align-items: center;
  display: flex;
}

.footer .fabs a .icon-viber .button-viber {
  color: #000;
  opacity: 0;
  background-color: #fff;
  border: .25px solid #00000080;
  border-radius: 10px;
  margin-right: 16px;
  padding: 10px;
  font-family: e-Ukraine-light;
  font-size: 20px;
  line-height: 1;
  transition: opacity .2s, transform .2s;
  transform: translateX(100px);
}

.footer .fabs a:hover .button-viber {
  opacity: 1;
  transform: translateX(0);
}

ul {
  width: 100%;
  color: #000;
  font-family: e-Ukraine-light;
  font-size: 18px;
  line-height: 1.5;
}

ul.expand {
  display: block;
}

@keyframes dropDown {
  0% {
    opacity: .5;
    display: none;
    transform: translateY(-2px);
  }

  1% {
    opacity: .5;
    display: block;
    transform: translateY(-2px);
  }

  100% {
    opacity: 1;
    display: block;
    transform: translateY(0);
  }
}

.expand-ul h3 {
  color: #9b6d2a;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: url("../images/plus.svg") 0 .25em no-repeat;
  margin: 24px 0 16px;
  padding-left: 1.5em;
  font-family: e-Ukraine-regular;
  font-size: 24px;
  line-height: 1.5;
}

.expand-ul h3.expand {
  background-image: url("../images/minus.svg");
}

.expand-ul ul {
  display: none;
}

.expand-ul ul.expand {
  opacity: 1;
  animation: .25s ease-out dropDown;
  display: block;
}

.expand-ul-show ul {
  display: inherit;
}

.docs-list .individual-note {
  color: #000;
  font-family: e-Ukraine-light;
  line-height: 1.5;
  font-size: var(--fs-individual-note);
  margin-top: 2.5em;
}

.docs-list h2 {
  color: #000;
  width: 100%;
  max-width: 1015px;
  margin-bottom: 40px;
  font-family: e-Ukraine-light;
  font-size: 24px;
  line-height: 1.5;
  display: block;
}

.docs-list h3 {
  color: #9b6d2a;
  margin: 24px 0 16px;
  font-family: e-Ukraine-regular;
  font-size: 24px;
  font-weight: 200;
  line-height: 1.5;
}

.docs-list .note {
  max-width: 950px;
}

.docs-list .note h2 {
  color: #000;
  margin: 0 0 1.5em;
  font-family: kyivTypeSans-regular;
  font-size: 40px;
  line-height: 1;
}

.docs-list .note ul {
  display: inherit;
  margin: 0;
}

.docs-list .note ul li {
  background: url("../images/rectangle.svg") 0 no-repeat;
  margin-top: 2em;
  padding-left: 2em;
  list-style: none;
}

.docs-list .note ul li:first-child {
  margin-top: 0;
}

.container h1 {
  margin: 1.538em 0 .615em;
}

.doc-in-list {
  max-width: 1012px;
  box-sizing: border-box;
  margin: 4em 0;
}

.doc-in-list:last-child {
  margin-bottom: 0;
}

.doc-in-list .circle-border {
  vertical-align: middle;
  color: #000;
  height: 1.4em;
  width: 1.4em;
  min-width: 1.4em;
  border: 1px solid #000;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: .5em;
  font-family: e-Ukraine-regular;
  font-size: 16px;
  line-height: 1;
  display: inline-flex;
}

.doc-in-list h2 {
  color: #000;
  font-family: e-Ukraine-light;
  font-size: 24px;
  font-weight: 200;
  line-height: 1;
  display: flex;
}

.doc-in-list h3 {
  padding: 1.8em 0;
  font-size: 18px;
  font-weight: 200;
}

.doc-in-list-empty {
  height: 220px;
}

.doc-page .breadcrumb {
  margin-top: 2.5em;
  font-size: 16px;
}

.doc-page .breadcrumb a {
  color: #000;
  background: url("../images/back.svg") 0 no-repeat;
  padding: 0 0 0 28px;
  text-decoration: none;
}

.doc-page h1 {
  max-width: 1100px;
  line-height: 1.25;
  font-size: var(--fs-h1-doc);
  margin: 1em 0 .6em;
}

.doc-page .notes {
  margin-top: 2em;
}

.doc-page .general-notes {
  font-size: var(--fs-general-notes);
  margin: 1em 0 2em;
}

.doc-page h2 {
  color: #000;
  width: 100%;
  max-width: 1020px;
  margin-bottom: 40px;
  font-family: e-Ukraine-light;
  font-size: 24px;
  line-height: 1.5;
  display: block;
}

.doc-page h3 {
  color: #9b6d2a;
  margin: 24px 0 16px;
  font-family: e-Ukraine-regular;
  font-size: 24px;
  line-height: 1.5;
}

.doc-page ul {
  list-style: inherit;
  color: #000;
  margin-bottom: 2em;
  font-family: e-Ukraine-light;
  font-size: 18px;
  line-height: 1.5;
  list-style-position: outside;
}

.doc-page ul li {
  margin-left: 3.1em;
  padding: .2em;
}

.doc-page .note li {
  font-size: var(--fs-note);
  margin-left: 0;
}

.doc-page .note {
  max-width: 1020px;
  margin-top: 4em;
}

.doc-page .note h2 {
  color: #000;
  margin: 0 0 1.5em;
  font-family: kyivTypeSans-regular;
  font-size: 40px;
  line-height: 1;
}

.doc-page .note ul {
  display: inherit;
  margin: 0;
}

.doc-page .note ul li {
  background: url("../images/rectangle.svg") 0 no-repeat;
  margin-top: 1.5em;
  padding-left: 2em;
  list-style: none;
}

.doc-page .note ul li:first-child {
  margin-top: 0;
}

.line-mb {
  margin-bottom: 1em;
}

hr {
  --Line-end-mt-doc: 12em;
  --Line-end-mt: 9em;
  --Line-end-mb: 1em;
  font-size: 20px;
}

.line-end {
  margin: var(--Line-end-mt) 0 var(--Line-end-mb) 0;
}

.line-end-doc {
  margin: var(--Line-end-mt-doc) 0 var(--Line-end-mb) 0;
}

:root {
  --block-mt-mb: 4em;
  --block-mt: 4em;
}

.block-mt-mb {
  margin: var(--block-mt-mb) 0;
  max-width: 1020px;
}

.block-mt-mb:last-child, .block-mt-mb .h2-mb-null {
  margin-bottom: 0;
}

.block-mt-mb .expand-2 li {
  margin-left: 1.2em;
}

.block-mt-mb .no-margin-bottom {
  margin-bottom: 0;
}

.zapovit {
  margin-bottom: 2em !important;
}

.block-mt-mb-big {
  margin: var(--block-mt) 0 0 0;
}

hr {
  height: 0;
  background-color: #000;
  border: none;
  border-top: 1px solid #000;
  position: relative;
}

.container {
  box-sizing: border-box;
  color: #000;
  font-family: e-Ukraine-light;
  line-height: 1.5;
}

.aspect-photo-img {
  aspect-ratio: 1.2;
}

.aspect-photo-img-2 {
  aspect-ratio: 1.41;
}

.block-img-rb-border {
  padding-right: var(--frame-space);
  padding-bottom: var(--frame-space);
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
}

.block-img-rb-border picture {
  width: 100%;
  height: auto;
  display: block;
}

.block-img-rb-border picture img {
  width: 100%;
  display: block;
}

.a-rect-top {
  height: calc(100% + var(--frame-space));
  width: 100%;
  transform: translateY(calc(-1 * var(--frame-space)));
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.a-rect-bottom {
  height: calc(100% + var(--frame-space));
  width: 100%;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.img {
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
}

.block-img-rb-frame {
  width: 100%;
  padding-bottom: var(--frame-space);
  position: relative;
}

.block-img-rb-frame .frame {
  box-sizing: border-box;
  width: calc(100% - var(--frame-space));
  height: calc(100% - var(--frame-space));
  top: var(--frame-space);
  left: var(--frame-space);
  border: 1px solid #000;
  position: absolute;
}

.block-img-rb-frame picture {
  width: calc(100% - var(--frame-space));
  height: auto;
}

.block-img-rb-frame picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  height: 100%;
  display: block;
}

.block-img-lt-frame {
  width: 100%;
  position: relative;
}

.block-img-lt-frame .frame {
  box-sizing: border-box;
  width: calc(100% - var(--frame-space));
  height: calc(100% - var(--frame-space));
  border: 1px solid #000;
  position: absolute;
  top: 0;
  left: 0;
}

.block-img-lt-frame picture {
  margin: var(--frame-space) 0 0 var(--frame-space);
}

.block-img-lt-frame picture img {
  width: 100%;
}

.block-img-corners {
  position: relative;
}

.block-img-corners img, .block-img-corners picture {
  width: 100%;
}

@media screen and (width >= 768px) and (width <= 1024px) {
  :root {
    --frame-space: 16px;
  }

  .mobOnly {
    display: none !important;
  }

  .deskOnly {
    display: initial;
  }

  .header {
    font-size: 16px;
  }

  .burger-hover {
    display: none;
  }

  .container {
    max-width: 1310px;
    box-sizing: border-box;
    color: #000;
    margin: 0 auto;
    padding: 0 15px;
    font-family: e-Ukraine-light;
    line-height: 1.5;
  }

  .first-block {
    width: 75%;
  }

  .fabs {
    bottom: 60px;
  }

  @keyframes bounce {
    0%, 20%, 100% {
      transform: translateY(0);
    }

    5% {
      transform: translateY(-10px);
    }

    10% {
      transform: translateY(-6px);
    }

    15% {
      transform: translateY(-10px);
    }
  }

  .aspect-header-img {
    aspect-ratio: 2.5;
  }

  .aspect-folders-img {
    aspect-ratio: 3.75;
  }

  .aspect-gavel-img {
    aspect-ratio: 2.77;
  }

  .block-img-rb-border {
    width: 100%;
  }
}

@media screen and (width >= 1025px) {
  .mobOnly {
    display: none !important;
  }

  .deskOnly {
    display: initial;
  }

  .burger-hover {
    display: none;
  }

  .container {
    max-width: 1310px;
    box-sizing: border-box;
    color: #000;
    margin: 0 auto;
    padding: 0 15px;
    font-family: e-Ukraine-light;
    line-height: 1.5;
  }

  .first-block {
    height: 537px;
    flex-flow: column wrap;
    place-content: space-between;
    display: flex;
  }

  .first-block .biogr-1 {
    width: 50%;
    top: 0;
  }

  .first-block .biogr-2 {
    width: 50%;
    order: 1;
  }

  .fabs {
    bottom: 60px;
    right: calc(50% - 640px);
  }

  @keyframes bounce {
    0%, 20%, 100% {
      transform: translateY(0);
    }

    5% {
      transform: translateY(-10px);
    }

    10% {
      transform: translateY(-6px);
    }

    15% {
      transform: translateY(-10px);
    }
  }

  .aspect-header-img {
    aspect-ratio: 2.5;
  }

  .aspect-folders-img {
    aspect-ratio: 3.75;
  }

  .aspect-gavel-img {
    aspect-ratio: 2.77;
  }

  .block-img-rb-border {
    width: 620px;
    height: auto;
  }
}

@media screen and (width >= 1025px) and (width <= 1151px) {
  .notary-services .container .services-list .services {
    width: 48%;
  }

  .fabs {
    bottom: 60px;
  }
}

@media screen and (width <= 1300px) {
  .fabs {
    right: 16px;
  }
}

@media screen and (width >= 769px) and (width <= 1024px) {
  :root {
    --fs-h1: 52px;
  }

  .notary-services .container h2 {
    margin-bottom: 20px;
  }

  .notary-services .container .services-list .services {
    width: 50%;
  }

  .notary-services .container .services-list .services:nth-child(-n+3) {
    margin-top: 60px;
  }

  .flex-box {
    align-items: inherit;
    flex-direction: column;
  }

  .header-menu ul li {
    margin-left: 40px;
  }

  .home {
    padding: 80px 0;
  }

  .home .home-block h1 {
    margin-top: 0;
    margin-bottom: 32px;
  }

  .home .home-block p {
    margin-bottom: 60px;
  }

  .home .home-block .home-telefon {
    width: 420px;
    margin-top: 60px;
    padding: 0 0 0 17px;
  }

  .home .home-block .home-telefon .contacts ul {
    flex-wrap: wrap;
    column-gap: 20px;
    display: flex;
  }

  .home .home-block .home-telefon .contacts ul li:last-child {
    margin-bottom: 0;
  }

  .home .home-block .home-telefon > div {
    margin-bottom: 10px;
  }

  .home .home-block .home-telefon > div a {
    font-size: 20px;
  }

  .notary-services {
    padding: 140px 0;
  }

  .job-description {
    padding: 80px 0;
  }

  .job-description .container .description {
    padding-bottom: 32px;
    padding-right: 20px;
    font-size: 20px;
    display: block;
  }

  .job-description .container .description .basis {
    padding-bottom: 24px;
    font-size: 20px;
  }

  h3 {
    font-size: 24px;
  }

  .advantages {
    padding: 140px 0 160px;
  }

  .advantages .container h2 {
    text-align: start;
    margin-bottom: 60px;
  }

  .advantages .container .list-desc {
    padding-top: 60px;
    padding-left: 0 !important;
  }

  .advantages .container .list-desc li {
    background: url("../images/rectangle.svg") 0 4px no-repeat;
    padding-bottom: 40px;
    padding-left: 1.9em;
    font-size: 18px;
  }

  .block-img-lt-frame {
    width: 75%;
  }

  .block-img-lt-frame img {
    width: calc(100% - var(--frame-space));
  }

  .customer .container .list-customer {
    width: 75%;
  }

  .customer .container .list-customer .list-office li:last-child {
    padding-bottom: 60px;
  }

  hr {
    --Line-end-mt: 10em;
    --Line-end-mb: 1em;
    font-size: 14px;
  }

  .line-end {
    margin: var(--Line-end-mt) 0 var(--Line-end-mb) 0;
  }

  .customer {
    padding: 160px 0 0;
  }

  .customer .container h2 {
    margin-bottom: 60px;
  }

  .section-olga-biography {
    width: 75%;
    padding: 140px 0;
  }

  .section-documents {
    background-color: #f8f7f3;
    padding: 80px 0 100px;
  }

  .section-documents .container h2 {
    text-align: center;
    margin-bottom: 1em;
  }

  .section-documents .container .list-documents {
    flex-direction: column;
  }

  .section-documents .container .list-documents .certificate {
    width: 70%;
    background: url("../images/rectangle.svg") top no-repeat;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 0 0;
    font-size: 20px;
  }

  .section-documents .container .list-documents .certificate:last-child {
    padding-bottom: 80px;
  }

  .section-experience {
    padding: 140px 0 0;
  }

  .section-experience .container h2 {
    margin-bottom: 1.5em;
  }

  .section-experience .container .text-experience {
    width: 75%;
  }

  .section-experience .container .text-experience li {
    background: url("../images/rectangle.svg") 0 no-repeat;
    margin-bottom: 2.2em;
    padding-left: 2.2em;
    font-size: 18px;
  }

  .section-experience .container .text-experience li:last-child {
    margin-bottom: 0;
  }

  .section-experience .container .parting-words {
    align-items: center;
    gap: 60px;
    margin-top: 140px;
  }

  .section-experience .container .parting-words .text-parting-words {
    text-align: center;
  }

  .section-experience .container .parting-words .text-parting-words p {
    font-size: 24px;
  }

  .section-experience .container .parting-words .block-img-rb-frame {
    width: 75%;
  }

  .section-block-contacts {
    padding: 80px 0;
  }

  .section-block-contacts .list-contacts {
    width: 75%;
  }

  .section-block-contacts .list-contacts .contacts ul {
    flex-wrap: wrap;
    column-gap: 20px;
    font-size: 18px;
    display: flex;
  }

  .section-block-contacts .list-contacts .contacts ul:last-child {
    margin-bottom: 1em;
  }

  .section-block-contacts .list-contacts .contacts ul a {
    font-size: 20px;
  }

  .section-block-contacts .list-contacts h1 {
    margin-top: -.3em;
    margin-bottom: 1.2em;
  }

  .section-block-contacts .list-contacts .home-telefon div {
    margin-bottom: 20px;
  }

  .section-block-contacts .list-contacts .home-telefon div a {
    color: #000;
    align-items: center;
    font-family: e-Ukraine-light;
    font-size: 20px;
    line-height: 1;
    text-decoration: none;
    display: flex;
  }

  .section-block-contacts .list-contacts .home-telefon div a img {
    margin-right: 10px;
  }

  .section-block-contacts .list-contacts .days-of-the-week {
    border-top: 1px solid #000;
    flex: 1;
    padding-top: 2em;
    display: inline-block;
  }

  .section-block-contacts .list-contacts .days-of-the-week li, .section-block-contacts .list-contacts .days-of-the-week p {
    font-size: 20px;
    line-height: 2em;
  }

  .section-block-contacts .list-contacts .block-img-notarius {
    height: 500px;
    width: 600px;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    padding: 0 20px 20px 0;
    display: block;
    left: 0;
  }

  .section-block-address h2 {
    margin-top: 3.5em;
    margin-bottom: 1.5em;
  }

  .section-block-address p {
    margin-bottom: 2em;
  }

  .section-block-address .map a {
    font-size: 20px;
  }

  .section-block-address .flex-address {
    flex-direction: row;
  }

  .section-block-address .flex-address .gmap-wrap {
    width: 50%;
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    padding: 0 0 20px 20px;
  }

  .section-block-address .flex-address .qr-wrap {
    height: auto;
    aspect-ratio: 1;
  }

  .section-block-address .flex-address .qr-wrap img {
    width: 53%;
    margin-bottom: .8em;
  }

  .section-block-address .flex-address .qr-wrap div {
    font-size: 16px;
  }

  .list-contacts.flex-box {
    align-items: flex-start;
  }
}

@media screen and (width <= 768px) {
  :root {
    --fs-footer-phone: 14px;
    --background-size: 20px 20px;
    --fs-h1: 36px;
    --fs-h1-doc: 32px;
    --fs-general-notes: 14px;
    --fs-note: 14px;
    --fs-individual-note: 12px;
    --block-mt-mb: 2em;
    --block-mt: 2em;
    --frame-space: 8px;
    --tb-height: 56px;
  }

  .mobOnly {
    display: initial !important;
  }

  .deskOnly {
    display: none !important;
  }

  .flex-box {
    flex-direction: column;
  }

  body {
    font-size: 18px;
  }

  .header .container {
    height: 100%;
    line-height: 1;
  }

  .header .container .header-main {
    height: 100%;
    padding: 0;
  }

  .header .container .header-main .header-logo img {
    height: 28px;
    display: block;
  }

  .burger-hover {
    width: 32px;
    height: 32px;
    z-index: 10;
    justify-content: center;
    align-items: center;
    display: flex;
    right: 16px;
  }

  .burger-hover .burger-icon {
    height: 12px;
    width: 24px;
  }

  .burger-hover .burger-icon .line-1, .burger-hover .burger-icon .line-2, .burger-hover .burger-icon .line-3 {
    width: 24px;
    background-color: #000;
    border-radius: 1px;
    transition: all .2s;
    position: absolute;
  }

  .burger-hover .burger-icon .line-1 {
    height: 2px;
    top: calc(50% - 6px);
  }

  .burger-hover .burger-icon .line-2 {
    height: 1px;
    top: 50%;
  }

  .burger-hover .burger-icon .line-3 {
    height: 2px;
    top: calc(50% + 6px);
  }

  .menu-open {
    overflow: hidden;
  }

  .menu-open main {
    pointer-events: none;
  }

  .menu-open .header-mob-inner {
    visibility: visible;
  }

  .menu-open .burger-icon .line-1 {
    transform-origin: center;
    top: 50%;
    transform: rotate(45deg);
  }

  .menu-open .burger-icon .line-2 {
    display: none;
  }

  .menu-open .burger-icon .line-3 {
    transform-origin: center;
    top: 50%;
    transform: rotate(-45deg);
  }

  .header-mob-inner {
    overscroll-behavior: contain;
    visibility: hidden;
    height: 100vh;
    width: 100vw;
    z-index: 1;
    background-color: #f8f7f3;
    padding-top: 4em;
    font-size: 32px;
    position: fixed;
    inset: 0;
    overflow-y: auto;
  }

  .header-mob-inner ul {
    flex-direction: column;
    align-items: center;
  }

  .header-mob-inner ul li {
    margin: 0;
  }

  .header-menu ul li a {
    color: #000;
    font-family: kyivTypeSans-regular;
    font-size: 32px;
    line-height: 1;
  }

  .container {
    color: #000;
    margin: 0;
    padding: 0 16px;
    font-family: e-Ukraine-light;
    line-height: 1.5;
  }

  .container h1 {
    margin: 40px 0 24px;
  }

  .container h3, .container p {
    font-size: 18px;
  }

  .aspect-header-img {
    aspect-ratio: 1.7;
  }

  .aspect-gavel-img {
    aspect-ratio: 1.8;
  }

  .aspect-folders-img {
    aspect-ratio: 2.35;
  }

  .block-img-rb-border {
    width: 100%;
  }

  .block-img-rb-frame {
    margin: 2.2em 0 0;
  }

  .block-img-lt-frame {
    width: 100%;
  }

  .block-img-lt-frame img, .block-img-lt-frame picture {
    width: calc(100% - var(--frame-space));
  }

  .block-img-corners {
    margin: var(--frame-space) 0;
  }

  .block-mt-mb:last-child {
    margin-bottom: 40px;
  }

  .doc-page h2 {
    margin: 40px 0 16px;
    font-size: 18px;
  }

  .doc-page ul {
    margin-bottom: 2em;
    font-size: 14px;
  }

  .doc-page ul li {
    margin-left: 2.6em;
    padding: .2em;
  }

  .doc-page .breadcrumb {
    margin-top: 2em;
    font-size: 14px;
  }

  .doc-page .breadcrumb a {
    font-size: 14px;
  }

  .doc-page .general-notes {
    margin: .7em 0 2em;
  }

  .doc-page .note {
    margin-top: 40px;
    font-size: 14px;
  }

  .doc-page .note ul li {
    background-size: 20px;
    margin-top: 2em;
  }

  .doc-page .note h2 {
    font-size: 24px;
  }

  .footer {
    padding: 43px 0;
  }

  .contacts {
    font-size: 14px;
  }

  .section-block-contacts {
    padding: 60px 0;
  }

  .section-block-contacts .list-contacts .contacts ul a, .section-block-contacts .list-contacts .contacts ul p {
    font-size: 18px;
  }

  .section-block-address h2 {
    text-align: center;
    margin-top: 80px;
    margin-bottom: 40px;
  }

  .section-block-address p {
    margin-bottom: 40px;
  }

  .section-block-address .flex-address {
    flex-direction: column;
    display: block;
  }

  .section-block-address .flex-address .gmap-wrap {
    width: 100%;
    height: 50vh;
    border: 1px solid silver;
    margin: 20px 0 40px;
    padding: 0;
  }

  .section-block-address .flex-address .qr-wrap {
    display: none;
  }

  hr {
    --Line-end-mt: 10em;
    --Line-end-mb: 1em;
    font-size: 8px;
  }

  .fabs {
    bottom: 36px;
  }

  .fabs .jump {
    animation: none;
  }

  .fabs .button-viber {
    display: none;
  }

  .doc-in-list a {
    font-size: 14px;
    line-height: 1;
  }

  p {
    font-size: 14px;
  }

  .expand-p h2 {
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    background: url("../images/down-arrow.svg") 100% .45em no-repeat;
    margin-bottom: 24px;
    padding-right: 1.3em;
    font-size: 18px;
    line-height: 1.25;
  }

  .expand-p h2.expand {
    background-image: url("../images/up-arrow.svg");
  }

  .expand-p h3 {
    padding-top: 1px;
    display: none;
  }

  .expand-p h3.expand {
    display: inherit;
    opacity: 1;
    font-size: 14px;
    animation: .25s ease-out dropDown;
  }

  .expand-ul h3 {
    background-position: 0;
    background-size: 18px;
  }

  .expand-ul ul {
    font-size: 14px;
  }

  .doc-in-list {
    margin: 2.2em 0;
  }

  .doc-in-list h3 {
    padding-right: 1.5em;
  }

  .doc-in-list .circle-border {
    height: 1.5em;
    width: 1.5em;
    min-width: 1.5em;
    margin-top: .2em;
    font-size: 10px;
  }

  .home {
    padding: 40px 0 60px;
  }

  .home .home-block h1 {
    margin-top: 0;
    margin-bottom: 24px;
  }

  .home .home-block .block-img-rb-frame {
    margin: 2em 0;
  }

  .home .home-block .home-telefon {
    width: 275px;
    padding: 0 0 0 10px;
  }

  .home .home-block .home-telefon > div {
    margin-bottom: 10px;
  }

  .home .home-block .home-telefon > div a {
    font-size: 18px;
  }

  .home .home-block .contacts li {
    margin-bottom: .5em;
  }

  h2 {
    font-size: 24px;
  }

  a {
    font-size: 16px;
  }

  .notary-services {
    padding: 80px 0;
  }

  .notary-services .container h2 {
    text-align: center;
    margin-bottom: 40px;
  }

  .notary-services .container h3 {
    font-size: 14px;
  }

  .notary-services .container .services-list {
    flex-direction: column;
    font-size: 14px;
    display: flex;
  }

  .notary-services .container .services-list .services {
    width: 100%;
    background: url("../images/rectangle.svg") top / 20px 20px no-repeat;
    margin-top: 32px;
    padding-top: 2em;
  }

  .notary-services .container .services-list .services:nth-child(n+2) {
    margin-top: 32px;
  }

  .notary-services .container .services-link {
    margin-top: 60px;
  }

  .notary-services .container .services-link a {
    font-size: 16px;
  }

  .job-description {
    background-color: #f8f7f3;
    padding: 60px 0;
  }

  .job-description .container {
    font-size: 0;
  }

  .job-description .container h2 {
    text-align: left;
  }

  .job-description .container .description {
    margin-top: 2.5em;
    padding-bottom: 12px;
    padding-right: 12px;
    font-size: 16px;
    display: block;
  }

  .job-description .container .description .basis {
    padding-bottom: 24px;
    font-size: 16px;
  }

  h3 {
    font-size: 18px;
  }

  .advantages {
    padding: 80px 0;
  }

  .advantages .container h2 {
    margin-bottom: 40px;
  }

  .advantages .container .list-advantages .list-desc {
    margin-top: 0;
    padding-top: 40px;
    padding-left: 0;
  }

  .advantages .container .list-advantages .list-desc li {
    background: url("../images/rectangle.svg") 0 4px / 20px 20px no-repeat;
    padding-bottom: 32px;
    padding-left: 1.9em;
    font-size: 14px;
  }

  .customer {
    padding: 80px 0 0;
  }

  .customer .container h2 {
    margin-bottom: 40px;
  }

  .customer .container .list-customer {
    display: block;
  }

  .customer .container .list-customer .list-office li {
    background: url("../images/rectangle.svg") 0 5px / 20px 20px no-repeat;
    padding-bottom: 32px;
    padding-left: 1.9em;
    font-size: 14px;
  }

  .customer .container .list-customer .list-office li:last-child {
    padding-bottom: 0;
  }

  .customer .container .line {
    border-bottom: 1px solid #000;
    margin: 100px 0 8px;
  }

  .photo-block .content-img {
    width: 100%;
    height: auto;
    display: flex;
  }

  .photo-block .block-img-rb-frame {
    margin: 1em 0 0;
  }

  .section-olga-biography {
    padding: 80px 0;
  }

  .section-olga-biography .list-biography {
    display: block;
  }

  .section-olga-biography .list-biography .text-biography {
    padding-bottom: 40px;
  }

  .section-olga-biography .list-biography .text-biography h3 {
    margin-bottom: 40px;
  }

  .section-olga-biography .list-biography .text-biography p {
    margin-bottom: 1.8em;
    font-size: 14px;
  }

  .section-olga-biography .list-biography .text-biography p:last-child {
    margin-bottom: 0;
  }

  .section-olga-biography .list-biography .block-img-rb-frame {
    margin: 0;
  }

  .section-documents {
    background-color: #f8f7f3;
    padding: 60px 0;
  }

  .section-documents .container h2 {
    text-align: center;
    margin-bottom: 1.7em;
  }

  .section-documents .container .list-documents {
    flex-direction: column;
    gap: 0;
    margin-top: 40px;
  }

  .section-documents .container .list-documents .certificate {
    width: 100%;
    background: url("../images/rectangle.svg") top no-repeat;
    padding-bottom: 32px;
    font-size: 14px;
  }

  .section-documents .container .list-documents .certificate:last-child {
    padding-bottom: 40px;
  }

  .section-documents .container .list-documents .certificate .documents-link a {
    font-family: e-Ukraine-regular;
    font-size: 14px;
    text-decoration: none;
  }

  .section-experience {
    padding: 80px 0 0;
  }

  .section-experience .container h2 {
    margin-bottom: 40px;
  }

  .section-experience .container .text-experience {
    max-width: 1005px;
  }

  .section-experience .container .text-experience li {
    background-position-y: 0;
    background-size: 20px;
    font-size: 14px;
  }

  .section-experience .container .text-experience li:last-child {
    margin-bottom: 0;
  }

  .section-experience .container .parting-words {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-top: 80px;
  }

  .section-experience .container .parting-words .text-parting-words {
    text-align: center;
  }

  .section-experience .container .parting-words .text-parting-words p {
    font-size: 18px;
  }

  .section-experience .container .parting-words .block-img-rb-frame {
    margin: 0;
  }

  a.full-button {
    width: 100%;
    text-align: center;
    color: #fff;
    background-color: #9b6d2a;
    margin: 0;
    padding: .75em 0;
    line-height: 1em;
    text-decoration: none;
    display: block;
  }

  a.full-button :active {
    background-color: #9b6d2acc;
  }
}

/*# sourceMappingURL=style.css.map */
