// About. section-olga-biography start
.section-olga-biography {
    padding: 180px 0;
    .list-biography {
        column-gap: 40px;
        align-items: start;
        .text-biography {
            h3 {
                margin-bottom: 40px;
            }
            p {
                @include element-text(1.5, $font-main, $font-Light);
                font-size: $fs-small-p;
                margin-bottom: 24px; 
            }
        }
        
    }
}
// about.section-olga-biography end

//about. section-documents start
.section-documents {
    padding: 80px 0 100px 0;
    background-color: $beige-color;
    .container {
            h2 {
                text-align: center;
            }
            .list-documents {
                font-size: $fs-small-p;
                text-align: center;
                display: flex;
                margin-top: 60px;
                gap: 59px;
                .certificate {
                    @include element-text(1.5, $font-main, $font-Light);
                    background: url("../images/rectangle.svg") no-repeat center top;
                    width: 33.3%;
                    padding: 40px 0 80px 0;
                    display: inline-block;
                    margin-top: 0;
                    justify-content: space-between;
                    .documents-link {
                        a {
                            text-decoration: none;
                            font-family: $font-Regular;
                            font-size: $fs-small-p;
                        }
                        a:hover {
                            color: $black;
                        }
                    }
                }
            }
    }
}
//about. section-documents end

//about. section-experience start
.section-experience {
    padding: 180px 0 0 0;
    .container {
        h2 {
            margin-bottom: 1.5em;
        }  
        .text-experience {
            max-width: 1005px;
                li {
                    font-size: $fs-ul;
                    margin-bottom: 2.2em;
                    padding-left: 2.2em;
                    background: url("../images/rectangle.svg")  0px center no-repeat;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
        }
        .parting-words {
            // align-items: center;
            margin-top: 180px;
            gap: 40px;
            .text-parting-words {
                // vertical-align: middle;
                text-align: center;
                p {
                    font-size: 24px;
                }
            }
        }
    }
}
//about. section-experience end