:root {
    --fs-message: 18px;
    --fs-a: 16px;
}

.body-error {
    padding-top: 0;
    background-color: #f8f7f3;
    display: block;
}

.sec-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: center;
    
}

.img-error {
    text-align: center;
    margin: 0 18%;
    max-width: 794px;
    img {
        width: 100%;
    }

}

.mes {
    @include element-text(1.5, $font-main, $font-Light);
    font-size: var(--fs-message);
    text-align: center;
    margin-top: 3em;
    white-space: nowrap;
    br {
        display: none;
    }
}

.button {
    
    padding: 12px 93px;
    margin-top: 1.4em;
    background-color: #9B6D2A;
    a {
        @include element-text(1.5,  #FFFFFF, $font-Light);
        font-size: var(--fs-a);
        text-decoration: none;
    }
}


@media screen and (max-width: 768px) {
    :root {
        --fs-message: 14px;
        --fs-a: 16px;
    }

    .img-error {
        margin: 0 5%;
        max-width: 288px;
    }
    .message {
        br {
            display: inline;
        }
    } 
}

// .container {
//     position: relative;
//     border: 1px black solid;
// }
  
// .background-404 {
//     @include element-text(1.5, rgba(155, 109, 42, 0.25), $font-Kyiv-Regular);
//     font-size: 500px;
//     position: relative;
//     text-align: center;
//     border: 1px black solid;

// }

// .text-404 {
//     @include element-text(1.5, $font-main, $font-Kyiv-Regular);
//     font-size: 200px;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -30%);
//     border: 1px black solid;
// }



